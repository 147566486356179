import React, {Component} from 'react';
import {connect} from "react-redux";
import {Switch, Route} from 'react-router-dom';
import routes from '../routers';
import {loginAction, registerAction} from "../data/actions/authAction";
import {
    Icon
} from './common';
import {message, Modal} from "antd";
import "../assets/pages/login.scss"
import TAG_DEFINE from "../constants/common";
import ForgotPassword from "./common/components/ForgotPassword";
import queryString from "query-string";
import * as LocalStorage from "../utils/localStorage";
import {CONFIG_LOCAL_STORAGE} from "../constants/define";

// import '../assets/layout/css/pages/login-register-lock.css'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isForgotPassword: false,
            fetchingLogin: true,
            email: "",
            password: "",
            checkAuth: false,
            registerSuccess: false,
            register: {
                email: "",
                password: "",
                confirm_password: "",
                first_name: "",
                last_name: ""
            }
        }
    }

    componentDidMount() {
        const signUpButton = document.getElementById('signUp'),
            signInButton = document.getElementById('signIn'),
            container = document.getElementById('id_login_container'),
            paramsSearch = queryString.parse(this.props?.location?.search);
        if (Object?.keys(paramsSearch)?.length > 0) {
            if (paramsSearch?.ref) {
                LocalStorage.set(CONFIG_LOCAL_STORAGE.REFERENCE, paramsSearch?.ref);
                this.setState({
                    register: {
                        ...this.state.register,
                        ref: paramsSearch?.ref
                    }
                });
            } else {
                if (LocalStorage.get(CONFIG_LOCAL_STORAGE.REFERENCE)) {
                    this.setState({
                        register: {
                            ...this.state.register,
                            ref: LocalStorage.get(CONFIG_LOCAL_STORAGE.REFERENCE)
                        }
                    });
                }
            }
            if (paramsSearch?.type === "r") {
                container.classList.add("right-panel-active");
            }
        } else {
            if (LocalStorage.get(CONFIG_LOCAL_STORAGE.REFERENCE)) {
                this.setState({
                    register: {
                        ...this.state.register,
                        ref: LocalStorage.get(CONFIG_LOCAL_STORAGE.REFERENCE)
                    }
                });
            }
        }

        signUpButton.addEventListener('click', () => {
            container.classList.add("right-panel-active");
        });

        signInButton.addEventListener('click', () => {
            container.classList.remove("right-panel-active");
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            this.setState({checkAuth: nextProps.checkAuth.logging})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.checkAuth?.registerSuccess !== prevProps.checkAuth?.registerSuccess)) {
            this.setState({
                registerSuccess: this.props.checkAuth?.registerSuccess
            })
            if (this.props.checkAuth?.registerSuccess) {
                const container = document.getElementById('id_login_container');
                container.classList.remove("right-panel-active");
                this.setState({
                    register: {
                        email: "",
                        password: "",
                        confirm_password: "",
                        first_name: "",
                        last_name: ""
                    }
                })
                Modal.success({
                    title: TAG_DEFINE.VALIDATION.statusCode.code["201"],
                    content: `Thank you for creating the account, you can now log in with the provided information`,
                });
            }
        }
    }

    onChange = (e) => {
        let target = e.target;
        let name = target.name;
        let value = target.value;
        this.setState({
            [name]: value
        });
    };

    onChangeRegister = (e) => {
        let target = e.target;
        let name = target.name;
        let value = target.value;
        this.setState({
            register: {
                ...this.state.register,
                [name]: value
            }
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.login();
    };

    onRegister = (e) => {
        e.preventDefault();
        if (this.state.register.password) {
            if (this.state.register.password === this.state.register.confirm_password) {
                if ((/^\s+|\s+$/g.test(this.state.register.password[0]) || /^\s+|\s+$/g.test(this.state.register.password[this.state.register.password.length - 1])) || /^\s+|\s+$/g.test(this.state.register.confirm_password [0]) || /^\s+|\s+$/g.test(this.state.register.confirm_password [this.state.register.confirm_password.length - 1])) {
                    message.error("'password' cannot be empty")
                } else {
                    if ((this.state.register?.password || "").length < 6) {
                        message.error("Password minimum 6 characters")
                    } else {
                        this.props.onActionRegister(this.state.register);
                    }
                }
            } else {
                message.error("Password does not match")
            }
        } else {
            message.error("Password is required")
        }
    };

    login() {
        const redirectTo = (this.props?.history?.location?.state?.from?.pathname) ? `${this.props?.history?.location?.state?.from?.pathname}${this.props?.history?.location?.state?.from?.search}` : "/"
        this.props.onLogin({...this.state, redirectTo});
        this.setState({fetchingLogin: false})
    }

    render() {
        const {email, password, fetchingLogin, checkAuth, isForgotPassword} = this.state;
        return (
            <div className="hn__login">
                <div id="login-page--id">
                    <ForgotPassword isForgotPassword={isForgotPassword} actionModal={(e) => this.setState({
                        isForgotPassword: e
                    })}/>
                </div>
                <div className="text-center mb-3">
                    <img src="/layout/logo-login.svg"
                         className="" width="200px"/>
                </div>
                <div className="container login_container login__form" id="id_login_container">
                    <div className="form-container sign-up-container px-4">
                        <form method="POST"
                              onSubmit={this.onRegister}>
                            <h3 className="mb-2 fs--40px fw-bold">Create your account</h3>
                            <div>
                                <input className="float-start" style={{width: "47%", marginRight: "20px"}}
                                       name="last_name" value={this.state.register.last_name} required type="text"
                                       placeholder="Last Name"
                                       onChange={this.onChangeRegister}/>
                                <input className="float-start" style={{width: "47%"}} name="first_name"
                                       value={this.state.register.first_name} required type="text"
                                       placeholder="First Name"
                                       onChange={this.onChangeRegister}/>
                                <div className="clearfix"/>
                            </div>
                            <input name="email" value={this.state.register.email} type="email" required
                                   placeholder="Email"
                                   onChange={this.onChangeRegister}/>
                            <input name="password" value={this.state.register.password} type="password" max={6} required
                                   placeholder="Password"
                                   onChange={this.onChangeRegister}/>
                            <input name="confirm_password" value={this.state.register.confirm_password} type="password"
                                   max={6} required
                                   placeholder="Confirm password"
                                   onChange={this.onChangeRegister}/>
                            <button className="mt-3" disabled={checkAuth}>Sign Up {checkAuth &&
                                <Icon type="sync-outlined" spin/>}</button>
                            <p className="fs--11px color--gray mt-2" style={{
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                marginLeft: "25px",
                                marginBottom: "15px"
                            }}>By logging in, you agree to our <span
                                className="text-decoration-underline cursorPointer"
                                onClick={() => window.open("https://realestateiq.co/terms-and-conditions/", '_blank')}>terms of use</span> and <span
                                className="text-decoration-underline cursorPointer"
                                onClick={() => window.open("https://realestateiq.co/privacy-policy/", '_blank')}>privacy policy.</span>
                            </p>
                        </form>
                    </div>
                    <div className="form-container sign-in-container px-4" style={{paddingTop: "100px"}}>
                        <form method="POST"
                              onSubmit={this.onSubmit}>
                            <h1 className="mb-2 fs--40px fw-bold">Welcome back! Log In</h1>
                            <div style={{height: "130px"}}>
                                <input type="email" id="login" value={email} name="email" required placeholder="Email"
                                       onChange={this.onChange} className="fadeIn second"/>
                                <input type="password" id="password" required name="password" value={password}
                                       onChange={this.onChange} placeholder="Enter your password"
                                       className="fadeIn third"/>
                            </div>
                            <p className="w-100 cursorPointer color--gray text-decoration-underline text-end fs--14px"
                               onClick={() => this.setState({isForgotPassword: true})}>Forgot your password?</p>
                            <button className="mt-3" disabled={checkAuth}>Login {checkAuth &&
                                <Icon type="sync-outlined" spin/>}</button>
                            <p className="fs--11px color--gray mt-2" style={{
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                marginLeft: "25px",
                                marginBottom: "15px"
                            }}>By subscribing, you agree to our <span
                                className="text-decoration-underline cursorPointer"
                                onClick={() => window.open("https://realestateiq.co/terms-and-conditions/", '_blank')}>terms of use</span> and <span
                                className="text-decoration-underline cursorPointer"
                                onClick={() => window.open("https://realestateiq.co/privacy-policy/", '_blank')}>privacy policy.</span>
                            </p>
                        </form>
                    </div>
                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <div style={{
                                    position: "relative"
                                }}>
                                    <img src="/layout/banner/login-banner.png" className="w-100"/>
                                    {/*<h4 style={{color: "white", fontWeight: "bold", width: "100%"}}>Welcome <span*/}
                                    {/*    style={{color: "#FCCE01"}}>to RealEstateIQ</span></h4>*/}
                                    {/*<div>*/}
                                    {/*    Enter your personal details and start*/}
                                    {/*    journey with us*/}
                                    {/*</div>*/}
                                    <div style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        margin: "auto",
                                        bottom: "50px"
                                    }}>
                                        <button className="ghost fs--14px fw-bold" id="signIn">Log in</button>
                                    </div>
                                    <div style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        margin: "auto",
                                        top: "60px"
                                    }}>
                                        <p className="fs--40px fw-bold color--white" style={{lineHeight: 1.2}}>Webcome
                                            back! Log in</p>
                                    </div>
                                </div>
                            </div>
                            <div className="overlay-panel overlay-right">
                                <div style={{
                                    position: "relative"
                                }}>
                                    <img src="/layout/banner/login-banner.png" className="w-100"/>
                                    {/*<h4 style={{color: "white", fontWeight: "bold", width: "100%"}}>Welcome <span*/}
                                    {/*    style={{color: "#FCCE01"}}>to RealEstateIQ</span></h4>*/}
                                    {/*<div>*/}
                                    {/*    Enter your personal details and start*/}
                                    {/*    journey with us*/}
                                    {/*</div>*/}
                                    <div style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        margin: "auto",
                                        bottom: "50px"
                                    }}>
                                        <button className="ghost fs--14px fw-bold" id="signUp">CREATE ACCOUNT</button>
                                    </div>
                                    <div style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        margin: "auto",
                                        top: "60px"
                                    }}>
                                        <p className="fs--40px fw-bold color--white" style={{lineHeight: 1.2}}>Your
                                            journey begins here!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
            ;
    }
}

const mapStateToProps = state => {
    return {
        // categories: state.reducerCategory,
        checkAuth: state.authReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (params) => {
            dispatch(loginAction(params))
        },
        onActionRegister: (params) => {
            dispatch(registerAction(params))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

